import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Footer from "../components/footer"
import InvestorHeader from "../components/Investor/header"
import UkHcpHeader from "../components/UkResidents/HCP/header"
import UkNonHcpHeader from "../components/UkResidents/NonHCP/header"
import NonUkHcpHeader from "../components/NonUkResidents/HCP/header"
import NonUkNonHcpHeader from "../components/NonUkResidents/NonHCP/header"
// import styles from "../components/landingPage.module.css"
import styles from "../components/header.module.css"

const NotFoundPage = (props) => {

  const getHeader = () => {
    const matches = (location) => props.location.pathname.startsWith(location);
    if (matches("/Investor")) return <InvestorHeader />;
    else if (matches("/UkResidents/HCP")) return <UkHcpHeader />;
    else if (matches("/UkResidents/Non-HCP")) return <UkNonHcpHeader />;
    else if (matches("/NonUkResidents/HCP")) return <NonUkHcpHeader />;
    else if (matches("/NonUkResidents/Non-HCP")) return <NonUkNonHcpHeader />;
    else return (
      <div className={styles.landing_logo}>
        <div className="container">
          <span className={styles.logo}></span>
        </div>
      </div>
    )
  }

  return (
        <div>
        {/* <div style={{minHeight: '80vh'}}> */}
      <SEO title="404: Not found" />
      
      {getHeader()}
      <div style={{minHeight:"calc(100vh - 317px"}}>
      
        <p className="zoom-area" style={{marginTop:"6.69em"}}><b>Oops!</b> The page you are looking for can not be found </p>  
        <section className="error-container">
          <span className="four"><span className="screen-reader-text">4</span></span>
          <span className="zero"><span className="screen-reader-text">0</span></span>
          <span className="four"><span className="screen-reader-text">4</span></span>
        </section>
        <section className="mobile_404 ">
          <p>404</p>
        </section>
        
        <div className="link-container pb-2">
          <Link  target="_blank" to="/" className="more-link">Go to home page</Link>
        </div>
     </div>
     <Footer/>
    </div>
  )
}

export default NotFoundPage
