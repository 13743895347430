import React from 'react';
import { Icon } from 'react-icons-kit'
import {facebook} from 'react-icons-kit/fa/facebook'
import {twitter} from 'react-icons-kit/fa/twitter'
import {googlePlus} from 'react-icons-kit/fa/googlePlus'
import {linkedin} from 'react-icons-kit/fa/linkedin'
import {dribbble} from 'react-icons-kit/fa/dribbble'
import Link from "gatsby-link"
class Footer extends React.Component {
    constructor(props){
        super()
        this.state = {}
    }
    render() { 
        return ( 
            <>

<footer className="page-footer font-small stylish-color-dark pt-4">

  
  <div className={"bottom_footer"}>
 

  
  <div className="footer-copyright  py-3">
      <div className={"container"} style={{borderTop: '1px solid #fff', padding: '30px 0 10px', fontSize: '15px'}}>
          <div className={"row"}>
              <div className={"col-12 col-md-4 text-left"}>
                                        ©{new Date().getFullYear()} Diurnal Ltd. All Rights Reserved  
          {` `}
              </div>
              <div className={"col-12 col-md-5 text-center"}>
              Terms of use | Privacy policy
              </div>
              <div className={"col-12 col-md-3 text-right"}>
              <a href="https://twitter.com/DiurnalLtd?s=20" target="blank" ><Icon icon={twitter} /></a>
              <a href="https://www.linkedin.com/company/diurnal-limited/" target="blank" ><Icon icon={linkedin} /></a>
              </div>
          </div>
   
      </div>
 
  </div>
 
  </div>
 


</footer>
            </>
         );
    }
}
 
export default Footer;